<template>
    <DataTable :value="clusterList" :paginator="true" class="p-datatable-gridlines" :rows="15" dataKey="id"
        :rowHover="true" v-model:filters="filtersCluster" filterDisplay="menu" :loading="loadingCluster"
        :filters="filtersCluster" responsiveLayout="scroll"
        :globalFilterFields="['name', 'country.name', 'representative.name', 'balance', 'status']">

        <template #header>
            <div class="grid grid-nogutter">
                <div class="col-6 text-left">
                    <!-- <Button label="添加集群" icon="pi pi-plus" class="p-button-primary mr-2"
                        @click="openAddCluster('add', '')" /> -->
                    <Button label="查看场地" class="p-button-secondary mr-2"
                        @click="viewFields(enterpriseId, -1, enterpriseName, '')" />
                </div>
                <div class="col-6 text-right">
                    <span class="p-input-icon-left mb-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filtersCluster['global'].value" placeholder="关键词搜索"
                            style="width: 100%" />
                    </span>
                </div>
            </div>
        </template>
        <template #empty>
            No customers found.
        </template>
        <template #loading>
            Loading customers data. Please wait.
        </template>
        <Column field="name" header="集群名称" style="min-width:15rem">
            <template #body="{ data }">
                {{ data.name }}
            </template>
        </Column>
        <Column header="联系人" style="min-width:8rem">
            <template #body="{ data }">
                {{ data.name }}
            </template>
        </Column>
        <Column header="联系方式" style="min-width:10rem">
            <template #body="{ data }">
                {{ data.tel }}
            </template>
        </Column>
        <Column header="操作" style="min-width:5rem">
            <template #body="{ data }">
                <span class="p-input-icon-left">
                    <Button label="场地" class="p-button-primary p-button-outlined mr-1"
                        @click="viewFields(enterpriseId, data.id, enterpriseName, data.name)" />
                    <Button label="编辑" class="p-button-success p-button-text mr-1"
                        @click="openAddCluster('edit', data)" />
                    <Button label="删除" class="p-button-danger p-button-text mr-1" @click="delCluster(data, $event)" />
                </span>
            </template>
        </Column>
    </DataTable>

    <Dialog :header="clusterOpName" v-model:visible="displayAddCluster" :style="{ width: '30vw' }" :modal="true">
        <div class="field p-fluid">
            <label for="enterpriseName">企业名称</label>
            <InputText id="enterpriseName" type="text" class="p-error" v-model="clusterForm.enterpriseinput"
                aria-describedby="username-help" />
            <small id="username-help" class="p-error" v-show="addEnterpriseNameError">请输入正确的企业名称，由中文、英文或者数字组成</small>
        </div>
        <div class="field p-fluid">
            <label for="managerName">管理员账号</label>
            <InputText id="managerName" class="p-error" v-model="clusterForm.adminNumber"
                aria-describedby="managername-help" />
            <small id="managername-help" class="p-error" v-show="addManagerNameError">请输入正确的管理员账号</small>
        </div>
        <div class="field p-fluid">
            <label for="managerPwd">密码</label>
            <Password id="managerPwd" class="p-error" aria-describedby="managerpwd-help"
                v-model="clusterForm.adminPassword" toggleMask></Password>
            <small id="managerpwd-help" class="p-error" v-show="addManagerPasswordError">请输入超过6位密码</small>
        </div>

        <template #footer>
            <Button label="取消" icon="pi pi-times" @click="closeAddCluster" class="p-button-text" />
            <Button label="确定" icon="pi pi-check" @click="doAddCluster" autofocus />
        </template>
    </Dialog>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api';
export default {
    data() {
        return {
            clusterList: [],
            clusterForm: {
                enterpriseinput: '',
                adminNumber: '',
                adminPassword: '',
                id: ''
            },

            groupBreadIndex: -1,
            enterpriseId: -1,
            enterpriseName: '',
            filtersCluster: null,
            clusterOpName: '新增集群',
            displayAddCluster: false,
            loadingCluster: false,
            sideindex: -1,
        }
    },
    created() {
        console.log('created')
        this.initFilters();
    },
    mounted() {
        console.log('mounted')

        this.enterpriseId = this.$route.query.ent_id;
        this.enterpriseName = this.$route.query.ent_name;
        this.groupBreadIndex = this.$route.query.bread_index;
        this.sideindex = this.$route.query.sideindex;

        this.getClusterList();
    },
    methods: {
        initFilters() {
            this.filtersCluster = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                'name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'country.name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
                'representative': { value: null, matchMode: FilterMatchMode.IN },
                'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
                'balance': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'status': { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
                'activity': { value: null, matchMode: FilterMatchMode.BETWEEN },
                'verified': { value: null, matchMode: FilterMatchMode.EQUALS }
            }
        },
        // 获取集群列表
        getClusterList() {
            console.log('getClusterList')
            this.$http('/api/admin/enterprise/indexx', {
                id: this.enterpriseId
            }).then(res => {
                if (res.code == 200) {
                    this.clusterList = res.data
                    console.log(this.clusterList)
                } else {
                    this.$toast.add({ severity: 'error', summary: '请求错误', detail: res.msg, life: 3000 });
                }
            })
        },
        doAddCluster() {
            if (this.enterpriseId == -1) {
                this.$toast.add({ severity: 'error', summary: '请选择父企业' , life: 3000 });
                return false;
            }

            this.$http('/api/admin/enterprise/create', {
                name: this.clusterForm.enterpriseinput,
                tel: this.clusterForm.adminNumber,
                pwd: this.clusterForm.adminPassword,
                enterprise_pid: this.enterpriseId,
                id: this.clusterForm.id
            }).then(res => {
                if (res.code == 200) {
                    this.$toast.add({ severity: 'success', summary: '操作成功' , life: 3000 });
                    this.displayAddCluster = false;
                    this.getClusterList();
                    this.$emit('updateEnterpriseList');
                } else {
                    this.$toast.add({ severity: 'error', summary: res.msg , life: 3000 });
                }
            })
        },
        // 场地删除
        delCluster(item, event) {
            this.$confirm.require({
                message: '确认刪除集群吗？',
                target: event.currentTarget,
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$http('/api/admin/enterprise/del', {
                        id: item.id
                    }).then(res => {
                        if (res.code == 200) {
                            this.$toast.add({ severity: 'success', summary: '操作成功', life: 3000  });
                            this.displayAddCluster = false;
                            this.getClusterList();
                            this.$emit('updateEnterpriseList');
                        } else {
                            this.$toast.add({ severity: 'error', summary: '操作失败', detail: res.msg, life: 2000 });
                        }
                    })
                },
                reject: () => {
                    this.displayAddCluster = false;
                    this.getClusterList();
                }
            });
        },
        closeAddCluster() {
            this.displayAddCluster = false;
        },
        openAddCluster(type, item) {
            if (this.enterpriseId == -1) {
                this.$toast.add({ severity: 'error', summary: '请选择父企业', life: 3000  });
                return false;
            }

            var company_name = this.enterpriseName;

            this.ischild = true
            if (type == 'add') {
                this.clusterOpName = '新增集群' + ' [' + company_name + ']';
                this.clusterForm = {
                    enterpriseinput: '',
                    adminNumber: '',
                    adminPassword: ''
                }
            } else {
                this.clusterOpName = '编辑集群' + ' [' + company_name + '->' + item.name + ']';
                this.clusterForm = {
                    enterpriseinput: item.name,
                    adminNumber: item.tel,
                    adminPassword: item.pwd,
                    id: item.id
                }
            }

            //集群与企业共用页面			
            this.displayAddCluster = true;
        },
        viewFields(enterprise, cluster, enterprise_name, cluster_name) {
            //this.$router.push({ name: 'setup', params: { name: this.enterprisearr[this.sideindex].name, id: this.enterprisearr[this.sideindex].id }})
            window.localStorage.setItem('enteridx', this.sideindex)

            ////清空groupBreadItems后面的数据，并配置当前选项
            var _grp_brd_idx = parseInt(this.groupBreadIndex, 10) + 1;
            var _grp_bread_items = JSON.parse(window.sessionStorage.getItem('groupBreadItems'));

            _grp_bread_items = _grp_bread_items.splice(0, _grp_brd_idx);

            var to_page = '/group/field';
            var _params = {
                ent_id: enterprise,
                ent_name: enterprise_name,
                cluster_id: cluster,
                cluster_name: cluster_name,
                bread_index: -1,
                back_index: _grp_brd_idx-1
            };

            if (cluster != -1) {
                _params.bread_index = _grp_brd_idx;
                _grp_bread_items.push({
                    label: cluster_name,
                    to: to_page,
                    style: 'p-menuitem-text',
                    params: _params
                });

                //保存数据到session
                window.sessionStorage.setItem('groupBreadIndex', _grp_brd_idx);
                window.sessionStorage.setItem('groupBreadItems', JSON.stringify(_grp_bread_items));

                console.log(_grp_bread_items)
                console.log(_grp_brd_idx)
            }

            //导航到Cluster页面
            this.$router.push({
                path: to_page,
                query: _params
            });

            this.$emit('updateData');
        },
    }
}
</script>